import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';

import { getGameLists, deleteGame } from '../../actions/games';
import { DEFAULT_PAGE_SIZE } from '../../utils/constants';
import advanceAjaxTableInit from '../../utils/advanceAjaxTableInit';
import { withRouter } from '../../withRouter';
import queryString from 'query-string';

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      gameList: [],
      total_items: 0,
      csvData: [],
    };
    this.handleDelete = this.handleDelete.bind(this);
  }
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getGameLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deleteGame(id);
      window.tableList.remove('_id', id);
    }
    return false;
  };
  generateCSV = () => {
    const result = [['url']];
    this.state.gameList.forEach((el) => {
      result.push([`https://playswap.gg/game/${el.slug}`]);
    });
    this.setState({ csvData: result });
  };
  generateTable = (games) => {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    const options = {
      page: 50,
      valueNames: ['name', 'slug', '_id'],
      pagination: {
        item: "<li><button class='page' type='button'></button></li>",
      },
      currentPage: parseInt(query.page) || 1,
    };

    window.tableList = advanceAjaxTableInit(options);
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.games !== this.props.games) {
      this.setState(
        {
          ...this.state,
          gameList: nextProps.games.games.data,
        },
        () => {
          this.generateCSV();
          this.generateTable(nextProps.games.games.data);
        },
      );
    }
  }
  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">All Games</li>
          </ol>
        </nav>
        {/* <h2 className="text-bold text-body-emphasis mb-5">Games</h2> */}
        <div id="advanceTable" data-list='{"page":12,"pagination":{"innerWindow":2,"left":1,"right":1}}'>
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    className="form-control search-input search fuzzy-search"
                    type="search"
                    placeholder="Search by Game"
                    aria-label="Search"
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  <CSVLink data={this.state.csvData}>Export</CSVLink>
                </button>
                <a href="games/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="_id"
                      style={{ width: '20%', minWidth: '100px' }}
                    >
                      GAME ID
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="name"
                      style={{ width: '30%', minWidth: '200px' }}
                    >
                      GAME NAME
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="slug"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      SLUG
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="priority"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      PRIORITY
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.gameList.length > 0 ? (
                    this.state.gameList.map((method, index) => (
                      <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="fs-9 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-8">
                            <input className="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                          </div>
                        </td>
                        <td className="_id align-middle white-space-nowrap">{method._id}</td>
                        <td className="align-middle white-space-nowrap">
                          <a className="d-flex align-items-center text-body text-hover-1000" href="#!">
                            <div className="avatar avatar-m">
                              <img
                                className="rounded-circle"
                                src={
                                  method.image_url ||
                                  'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                                }
                                alt=""
                              />
                            </div>
                            <h6 className="name mb-0 ms-2 fw-semibold">{method.name}</h6>
                          </a>
                        </td>
                        <td className="align-middle white-space-nowrap text-center">
                          <h6 className="slug mb-0 fw-semibold">{method.slug}</h6>
                        </td>
                        <td className="priority align-middle white-space-nowrap text-center">{method.priority}</td>
                        <td className="action align-middle white-space-nowrap text-body-tertiary text-end">
                          <div className="btn-reveal-trigger position-static">
                            <button
                              className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <span className="fas fa-ellipsis-h fs-10"></span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end py-2">
                              <a className="dropdown-item" href={`games/edit/${method._id}`}>
                                <i class="fa-solid fa-pen-to-square me-1"></i>Edit
                              </a>
                              <a
                                className="dropdown-item text-danger deleteGame"
                                onClick={() => this.handleDelete(method._id)}
                                href="#!"
                              >
                                <i class="fa-solid fa-trash me-1"></i> Remove
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div class="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info"></p>
                <a class="fw-semibold" href="#!" data-list-view="*">
                  View all
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
                <a class="fw-semibold d-none" href="#!" data-list-view="less">
                  View Less
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
              </div>
              <div class="col-auto d-flex">
                <button class="page-link" data-list-pagination="prev">
                  <span class="fas fa-chevron-left"></span>
                </button>
                <ul class="mb-0 pagination"></ul>
                <button class="page-link pe-0" data-list-pagination="next">
                  <span class="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Games.propTypes = {
  auth: PropTypes.object.isRequired,
  games: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getGameLists: PropTypes.func.isRequired,
  deleteGame: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  games: state.games,
});

export default connect(mapStatetoProps, {
  getGameLists,
  deleteGame,
})(withRouter(Games));
