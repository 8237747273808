import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { logoutUser, clearCurrentProfile } from '../../actions/auth';
import { createNotification } from '../../utils/helpers';
import { subscribeNotification, unsubscribeNotification } from '../../utils/notificationHelper';
import lang from '../../langs';
import logo from '../../img/logo.png';
import { notificationDataRead, notificationDataUpdate, allNotificationDataUpdate } from '../../actions/seller';
import { getSettings } from '../../actions/payment';

import { withRouter } from '../../withRouter';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      unread: 0,
    };
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  onClickNotification = (id, notification) => {
    if (notification?.id) {
      this.props.notificationDataUpdate(id, () => {
        this.props.notificationDataRead();
        this.props.router.navigate(this.renderURL(notification), {
          replace: true,
        });
      });
    }
  };

  markAsRead = () => {
    this.props.allNotificationDataUpdate(() => {
      this.props.notificationDataRead();
    });
  };

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();

    createNotification('info', lang('success.logout'));
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.getSettings();
      this.props.notificationDataRead();
      subscribeNotification(this.props.auth.user.uid, (data) => {
        this.props.notificationDataRead();
      });
    }
  }

  handleBeforeUnload = () => {
    if (this.props.auth.isAuthenticated) unsubscribeNotification(this.props.auth.user.uid);
  };

  componentWillUnmount() {
    this.handleBeforeUnload();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sellers.notifications !== this.props.sellers.notifications) {
      let messages = (nextProps.sellers.notifications?.data || []).filter((el) => {
        let response = JSON.parse(el.message);
        try {
          response = JSON.parse(response);
        } catch (e) { }
        return response !== 'New Message' && response?.msg && typeof response?.msg?.id == 'string';
      });
      messages = (messages || []).map((el) => {
        let response = JSON.parse(el.message);
        try {
          response = JSON.parse(response);
        } catch (e) { }
        el.message = response;
        return el;
      });
      const results = (messages || []).filter((el) => !el.is_read);
      this.setState({
        notifications: messages,
        unread: results.length,
      });
      if (results.length > 0) {
        new Audio('/ringtone.mp3').play();
      }
    }
  }

  renderURL = (message) => {
    if (message?.id && typeof message?.id == 'string') {
      switch (message?.type) {
        case 'Payout':
          return `/admin/marketplace/payout-report`;
        case 'Refund':
          return `/admin/marketplace/refund/${message?.id}`;
        case 'Dispute':
          return `/admin/support/view/${message?.id}`;
        case 'Other':
          return `/admin/support/view/${message?.id}`;
        case 'KYC':
          return `/admin/support/kyc`;
        default:
          return `/`;
      }
    }
  };

  renderTextStatus = (message) => {
    if (message?.id && typeof message?.id == 'string') {
      switch (message?.type) {
        case 'Payout':
          return `Payout Request: ${message?.id}`;
        case 'Refund':
          return `Refund Request: ${message?.id}`;
        case 'Dispute':
          return `New Dispute: ${message?.id}`;
        case 'Other':
          return `New Ticket: ${message?.id}`;
        case 'KYC':
          return `KYC Request: ${message?.id}`;
        default:
          return `You have new ${message?.type}`;
      }
    }
  };

  render() {
    return (
      <nav className="navbar navbar-top fixed-top navbar-expand" id="navbarDefault">
        <div className="collapse navbar-collapse justify-content-between">
          <div className="navbar-logo">
            <button
              className="btn navbar-toggler navbar-toggler-humburger-icon hover-bg-transparent"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarVerticalCollapse"
              aria-controls="navbarVerticalCollapse"
              aria-expanded="false"
              aria-label="Toggle Navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line"></span>
              </span>
            </button>
            <a className="navbar-brand me-1 me-sm-3" href="/admin">
              <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                  <img src={logo} alt="playswap" width="40" />
                  <p className="logo-text ms-2 d-none d-sm-block">PlaySwap</p>
                </div>
              </div>
            </a>
          </div>
          <ul className="navbar-nav navbar-nav-icons flex-row">
            <li className="nav-item">
              <div className="theme-control-toggle px-2">
                <input
                  className="form-check-input ms-0 theme-control-toggle-input"
                  type="checkbox"
                  data-theme-control="phoenixTheme"
                  value="dark"
                  id="themeControlToggle"
                />
                <label
                  className="mb-0 theme-control-toggle-label theme-control-toggle-light"
                  htmlFor="themeControlToggle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Light mode"
                >
                  <span className="icon" data-feather="moon"></span>
                </label>
                <label
                  className="mb-0 theme-control-toggle-label theme-control-toggle-dark"
                  htmlFor="themeControlToggle"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Dark mode"
                >
                  <span className="icon" data-feather="sun" ></span>
                </label>
              </div>
            </li>
            <li className="nav-item dropdown">
              <span
                className="nav-link position-relative"
                style={{ minWidth: '2.25rem' }}
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <span data-feather="bell" style={{ width: '100%' }}></span>
                {this.state.unread > 0 && (
                  <span class="position-absolute top-10 start-60 translate-middle badge border border-light rounded-circle bg-danger p-1">
                    <span class="visually-hidden">{this.state.unread}</span>
                  </span>
                )}
              </span>
              <div
                style={{ minWidth: '350px' }}
                className="dropdown-menu dropdown-menu-end notification-dropdown-menu py-0 shadow border navbar-dropdown-caret"
                id="navbarDropdownNotfication"
                aria-labelledby="navbarDropdownNotfication"
              >
                <div className="card position-relative border-0">
                  <div className="card-header p-2">
                    <div className="d-flex justify-content-between">
                      <h5 className="text-body-emphasis mb-0">Notifications</h5>
                      <button onClick={this.markAsRead} class="btn btn-link p-0 fs-10 fw-normal" type="button">
                        Mark all as read
                      </button>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="scrollbar-overlay" style={{ maxHeight: '27em' }}>
                      {this.state.notifications.map((el) => {
                        let msgTime = el.created_at ? new Date(el.created_at * 1000) : new Date();
                        const timeDisplay =
                          moment().diff(moment(msgTime), 'days') >= 1
                            ? moment(msgTime).format('lll')
                            : moment(msgTime).fromNow();
                        return (
                          <div
                            key={el._id}
                            className={`px-2 px-sm-3 py-3 notification-card position-relative ${!el.is_read ? 'unread' : ''
                              } border-bottom`}
                          >
                            <div className="d-flex align-items-center justify-content-between position-relative">
                              <div className="d-flex">
                                <div className="flex-1 me-sm-3">
                                  <h4 className="fs-9 text-body-emphasis">{el.message.msg.type}</h4>
                                  <p className="fs-9 text-body-highlight mb-2 mb-sm-3 fw-normal">
                                    <span
                                      role="button"
                                      dangerouslySetInnerHTML={{ __html: this.renderTextStatus(el.message.msg) }}
                                      onClick={() => this.onClickNotification(el._id, el.message.msg)}
                                    ></span>
                                  </p>
                                  <p className="text-body-secondary fs-9 mb-0">
                                    <span className="me-1 fas fa-clock"></span>
                                    <span className="fw-bold">{timeDisplay} </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link lh-1 pe-0"
                id="navbarDropdownUser"
                href="#!"
                role="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="avatar avatar-l" style={{ height: '32px', width: '32px' }}>
                  <img className="rounded-circle " src={this.props.auth.user.user_avatar} alt="" />
                </div>
              </a>
              <div
                style={{ minWidth: '300px' }}
                className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border"
                aria-labelledby="navbarDropdownUser"
              >
                <div className="card position-relative border-0">
                  <div className="card-body p-0">
                    <div className="text-center pt-4 pb-3">
                      <div className="avatar avatar-xl ">
                        <img className="rounded-circle " src={this.props.auth.user.user_avatar} alt="" />
                      </div>
                      <h6 className="mt-2 text-body-emphasis" >ADMIN</h6>
                    </div>
                    <div className="mb-3 mx-3">
                      <input
                        className="form-control form-control-sm"
                        id="statusUpdateInput"
                        type="text"
                        placeholder="Update your status"
                      />
                    </div>
                  </div>
                  <div className="overflow-auto scrollbar" style={{ height: '10em' }}>
                    <ul className="nav d-flex flex-column mb-2 pb-1">
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          {' '}
                          <span className="me-2 text-body" data-feather="user"></span>
                          <span>Profile</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          <span className="me-2 text-body" data-feather="pie-chart"></span>
                          Dashboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          {' '}
                          <span className="me-2 text-body" data-feather="lock"></span>
                          Posts &amp; Activity
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          {' '}
                          <span className="me-2 text-body" data-feather="settings"></span>
                          Settings &amp; Privacy{' '}
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          {' '}
                          <span className="me-2 text-body" data-feather="help-circle"></span>
                          Help Center
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          {' '}
                          <span className="me-2 text-body" data-feather="globe"></span>
                          Language
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer p-0 border-top border-translucent">
                    <ul className="nav d-flex flex-column my-3">
                      <li className="nav-item">
                        <a className="nav-link px-3" href="#!">
                          {' '}
                          <span className="me-2 text-body" data-feather="user-plus"></span>
                          Add another account
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <div className="px-3">
                      {' '}
                      <a
                        className="btn btn-phoenix-secondary d-flex flex-center w-100"
                        href="#!"
                        onClick={this.onLogoutClick}
                      >
                        {' '}
                        <span className="me-2" data-feather="log-out">
                          {' '}
                        </span>
                        Sign out
                      </a>
                    </div>
                    <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
                      <a className="text-body-quaternary me-1" href="#!">
                        Privacy policy
                      </a>
                      &bull;
                      <a className="text-body-quaternary mx-1" href="#!">
                        Terms
                      </a>
                      &bull;
                      <a className="text-body-quaternary ms-1" href="#!">
                        Cookies
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav >
    );
  }
}

Header.propTypes = {
  clearCurrentProfile: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  logoutUser,
  clearCurrentProfile,
  notificationDataRead,
  notificationDataUpdate,
  allNotificationDataUpdate,
  getSettings,
})(withRouter(Header));
