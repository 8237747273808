import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import {
  GET_ERRORS,
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_DETAIL,
  UPDATE_TRANSACTION,
  GET_PAYOUT_LIST,
  UPDATE_PAYOUT,
} from './types';

// Get List of Transactionss
export const getTransactionsLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/transaction?${params}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getAffTransactionsLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/affiliate?${params}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getAffPayoutsLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/affiliate/v1/auth/admin/affilite-payout?${params}`)
      .then((res) => {
        dispatch({
          type: GET_PAYOUT_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getTransactionsDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/order/${id}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getTransaction = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/admin/transaction/${id}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateTransaction = (id, values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/payment/v1/auth/admin/transaction/${id}`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_TRANSACTION,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getRefund = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/payment/v1/auth/admin/balance-history/${id}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

// Get List of Payouts
export const getPayoutsLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/payout?${params}`)
      .then((res) => {
        dispatch({
          type: GET_PAYOUT_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updatePayout = (id, payload) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/payment/v1/auth/admin/payout/${id}`, payload)
      .then((res) => {
        dispatch({
          type: UPDATE_PAYOUT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateAffPayout = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/affiliate/v1/auth/admin/affilite-payout/${id}`)
      .then((res) => {
        dispatch({
          type: UPDATE_PAYOUT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getRefundTransactionsLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/payment/v1/auth/admin/balance-history?${params}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateRefund = (id, payload) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .put(`/payment/v1/auth/admin/balance-history/${id}`, payload)
      .then((res) => {
        dispatch({
          type: UPDATE_PAYOUT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const deleteRefund = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/payment/v1/auth/admin/balance-history/${id}`, {})
      .then((res) => {
        dispatch({
          type: UPDATE_PAYOUT,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
        // reject(err);
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
