export const DEFAULT_PAGE_SIZE = 24;
export const SOCIAL_TYPE = ['...', 'WhatsApp', 'Discord', 'Facebook'];
export const USER_TYPE_ENUM = {
  INACTIVE: 0,
  USER: 1,
  STAFF: 2,
  ADMIN: 3,
};
export const USER_STATUS_ENUM = ['deactivated', 'active', 'blocked'];
export const KYC_STATUS_ENUM = ['Await for Approve', '', 'Approved', 'Unapproved'];
export const REVIEW_STATUS_ENUM = ['Await for Approve', 'Approved', 'Unapproved'];

export const TRANSACTION_STATUS_PENDING = 1; // create order
export const TRANSACTION_STATUS_WATING = 2; // buyer scan qr code or pay with pay platform
export const TRANSACTION_STATUS_PAYED = 3; // buyer payed
export const TRANSACTION_STATUS_COMPLETED = 4; // transaction success
export const TRANSACTION_STATUS_REFUND = 5; // buyer payed but seller not transfer item buyer, merchant transfer price for buyer success
export const TRANSACTION_STATUS_FAIL = 6; // when pay platform callback return status fail
export const TRANSACTION_STATUS_ON_HOLD = 7; // when seller confirmed but seller not confirm and after 30 minutes
export const TRANSACTION_STATUS_BUYER_NOT_RECEIVE = 8; // when buyer confirmed fail
export const TRANSACTION_STATUS_BUYER_RECEIVE = 9; // when buyer confirmed fail
export const TRANSACTION_STATUS_PARTIAL_REFUND = 10; // when buyer confirmed fail
export const TRANSACTION_STATUS_PAYMENT_PROCESSING = 11; // WAITING response
