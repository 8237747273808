import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const navigations = [
  {
    parent: 'SETTING',
    icon: '<i class="fa-solid fa-screwdriver-wrench"></i>',
    children: {
      'Admin': '/admin/settings/members',
      'Verified Users': '/admin/settings/users',
      'UnVerified Users': '/admin/settings/users/inactive',
      'Suspended Users': '/admin/settings/users/block',
      'Seller (Ranks)': '/admin/settings/seller-ranks',
      'Buyer (Ranks)': '/admin/settings/buyer-ranks',
      'Payment': '/admin/settings/payment-methods',
      'Payout': '/admin/settings/payout-methods',
      'Rate': '/admin/settings/payment',
    },
  },
  {
    parent: 'FINANCIAL',
    icon: '<i class="fa-solid fa-chart-pie"></i>',
    children: {
      'Financial report': '#',
      'Financial analytics': '#',
      'Expense Category': '/admin/accounting/expense-categories',
      'Expense': '/admin/accounting/expense',
    },
  },
  {
    parent: 'GAMES',
    icon: '<i class="fa-solid fa-gamepad"></i>',
    children: {
      'Game Type': '/admin/games/types',
      'Product Type': '/admin/games/product-types',
      'All Games': '/admin/games',

    },
  },
  {
    parent: 'MARKETPLACE',
    icon: '<i class="fa-solid fa-shop"></i>',
    children: {
      'Active Sellers': '/admin/marketplace/users',
      'InActive Sellers': '/admin/marketplace/users/inactive',
      'KYC': '/admin/support/kyc',
      'Products': '/admin/games/products',
      'Dispute': '/admin/support/disputes',
      'Ticket': '/admin/support/tickets',
      'Payout Request': '/admin/marketplace/payout-report',
      'Transactions': '/admin/marketplace/transactions',
      'Refund Request': '/admin/marketplace/refund',
      'Reviews': '/admin/marketplace/reviews',
    },
  },
  {
    parent: 'AFFILIATE',
    icon: '<i class="fa-solid fa-link"></i>',
    children: {
      'Transactions': '/admin/affiliate/transactions',
      'Payouts Request': '/admin/affiliate/payouts',
    },
  },
  {
    parent: 'MARKETING',
    icon: '<i class="fa-solid fa-headset"></i>',
    children: {
      'Pop Up': '/admin/marketing/banners',
      'Live Chat': '/admin/support/chat',
      'Subscribers': '/admin/marketplace/subscribers',
    },
  },

];

class Navbar extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <nav className="navbar navbar-vertical navbar-expand-lg">
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content">
            <ul className="navbar-nav flex-column" id="navbarVerticalNav">
              <li className="nav-item">
                <p className="navbar-vertical-label">Dashboard</p>
                <hr className="navbar-vertical-line" />
                {navigations.map((navigation, index) => (
                  <div key={index} className="nav-item-wrapper">
                    <a className="nav-link dropdown-indicator">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: navigation['icon'],
                            }}
                          />
                        </span>
                        <span className="nav-link-text">{navigation['parent']}</span>
                      </div>
                    </a>
                    <div className="parent-wrapper label-1">
                      <ul className="nav parent" data-bs-parent="#navbarVerticalCollapse" id="nv-CRM">
                        <li className="collapsed-nav-item-title d-none">User Manager</li>
                        {Object.keys(navigation['children']).map((el, index) => (
                          <li className="nav-item" key={index}>
                            <Link className="nav-link" to={navigation['children'][el]}>
                              <div className="d-flex align-items-center">
                                <span className="nav-link-text">{el}</span>{' '}
                                {navigation['children'][el] == '#' ? '*' : ''}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps, {})(Navbar);
