import rest from '../utils/rest';
import { beforeRequest, beforeResponse } from '../utils/helpers';
import { GET_ERRORS, GET_RANK_LIST, UPDATE_RANK, CREATE_RANK, DELETE_RANK, GET_RANK_DETAIL } from './types';

export const getRoyaltyLists = (filter) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams(filter);
    rest
      .get(`/membership/v1/auth/admin/loyalty?${params}`)
      .then((res) => {
        dispatch({
          type: GET_RANK_LIST,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const royaltyDataDelete = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .delete(`/membership/v1/auth/admin/loyalty?loyalty_id=${id}`, {})
      .then((res) => {
        dispatch({
          type: DELETE_RANK,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const createRoyalty = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/membership/v1/auth/admin/loyalty`, values)
      .then((res) => {
        dispatch({
          type: CREATE_RANK,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const updateRoyalty = (values) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .post(`/membership/v1/auth/admin/loyalty`, values)
      .then((res) => {
        dispatch({
          type: UPDATE_RANK,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};

export const getRoyaltyDetail = (id) => (dispatch) => {
  dispatch(beforeRequest());

  return new Promise((resolve, reject) => {
    rest
      .get(`/membership/v1/auth/admin/loyalty/${id}`)
      .then((res) => {
        dispatch({
          type: GET_RANK_DETAIL,
          payload: res.data ? res.data : null,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: "Somthing's wrong",
        });
      })
      .then(function () {
        dispatch(beforeResponse());
      });
  });
};
