import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getRoyaltyLists, royaltyDataDelete } from '../../../actions/royalty';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';

class BuyerRanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      royaltyList: [],
      total_items: 0,
    };
  }

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getRoyaltyLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.royaltyDataDelete(id);
      const royaltyList = this.state.royaltyList.filter((el) => el._id !== id);
      this.setState({
        ...this.state,
        royaltyList,
      });
    }
    return false;
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.royalties !== this.props.royalties) {
      this.setState({
        ...this.state,
        royaltyList: nextProps.royalties.royaltys.data,
      });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Buyer ranks</li>
          </ol>
        </nav>
        {/* <h4 className="text-bold text-body-emphasis mb-3 text-uppercase">Buyer ranks</h4> */}
        <div id="members">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    className="form-control search-input search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
                <a href="buyer-ranks/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      NAME
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      MILESTONE
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      TRANSACTION FEES
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      ORDER AMOUNT
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.royaltyList.map((royalty) => (
                    <tr class="hover-actions-trigger btn-reveal-trigger position-static">
                      <td class="fs-9 align-middle ps-0 py-3">
                        <div class="form-check mb-0 fs-8">
                          <input class="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                        </div>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold text-center">{royalty.rank}</h6>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold text-center">{royalty.total_revenue}</h6>
                      </td>
                      <td class="mobile_number align-middle white-space-nowrap text-center">{royalty.fee_charge_buyer}%</td>
                      <td class="mobile_number align-middle white-space-nowrap text-center">{royalty.order_amount}</td>
                      <td class="joined align-middle white-space-nowrap text-body-tertiary text-end">
                        <div class="btn-reveal-trigger position-static">
                          <button
                            class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-boundary="window"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-bs-reference="parent"
                          >
                            <span class="fas fa-ellipsis-h fs-10"></span>
                          </button>
                          <div class="dropdown-menu dropdown-menu-end py-2">
                            <a class="dropdown-item" href={`buyer-ranks/edit/${royalty._id}`}>
                              <i class="fa-solid fa-pen-to-square me-1"></i> Edit
                            </a>
                            <a class="dropdown-item text-danger" onClick={() => this.handleDelete(royalty._id)}>
                              <i class="fa-solid fa-trash me-1"></i> Remove
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

BuyerRanks.propTypes = {
  auth: PropTypes.object.isRequired,
  royalties: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getRoyaltyLists: PropTypes.func.isRequired,
  royaltyDataDelete: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  royalties: state.royalties,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStatetoProps, {
  getRoyaltyLists,
  royaltyDataDelete,
})(BuyerRanks);
