import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import md5 from 'md5';
import { Link } from 'react-router-dom';

import { getRoomLists } from '../../../actions/chat';
import { getUserLists } from '../../../actions/user';
import { DEFAULT_PAGE_SIZE, USER_STATUS_ENUM } from '../../../utils/constants';
import { isJson, handleChat, sendImage } from '../../../utils/chatHelper';
import config from '../../../config';

class ChatScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        field_name: 'status',
        field_value: 1,
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      roomList: [],
      userList: [],
      activeRoom: null,
      targetUser: {},
      height: '',
      image_url: '',
      icon: null,
    };
    this.uploadImage = this.uploadImage.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  setImageState = (file) => {
    this.setState({ icon: file });
  };

  uploadImage = async (file) => {
    const me = this;
    me.setImageState(null);
    const body = new FormData();
    body.append('file', file);
    body.append('path', `/categories/`);
    body.append('type_upload', 'product');
    body.append('name', 'product');

    fetch(`${config.url}/assets/v1/auth/admin/asset/upload`, {
      method: 'POST',
      body,
      headers: { 'access-token': sessionStorage.getItem(config.tokenKey) },
    })
      .then((response) => response.json())
      .then((json) => sendImage(json?.data?.img_urls[0]))
      .catch((err) => console.log(err));
  };

  handleImageUpload = (file) => {
    var fileTypes = ['jpg', 'jpeg', 'png', 'gif'];

    const fileSize = file.size;
    const fileMb = fileSize / 1024 ** 2;
    const me = this;
    var extension = file.name.split('.').pop().toLowerCase();

    if (file && fileMb <= 10 && fileTypes.indexOf(extension) >= 0) {
      const reader = new FileReader();

      reader.onload = function (e) {
        me.setImageState(file);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  getOrCreateChatSupport = (user_id) => {
    if (this.props.auth.user.uid !== user_id) {
      const targetUser = this.props.users.users.users.filter((user) => user.id == user_id);
      const newActiveRoom = `${md5(this.props.auth.user.uid + '_' + user_id)}`;
      if (targetUser[0]) {
        this.setState(
          {
            targetUser: targetUser[0] || {},
          },
          () => {
            this.setActiveRoom(newActiveRoom, {
              id: this.state.targetUser['id'],
              user_id: this.state.targetUser['id'],
              user_avatar: this.state.targetUser['avatar'],
            });
          },
        );
      }
    }
  };

  setActiveRoom = (roomId, otherUser) => {
    this.setState(
      {
        activeRoom: roomId,
      },
      () => {
        this.sortChatRooms();
        handleChat(
          {
            id: this.props.auth.user.uid,
            user_id: this.props.auth.user.uid,
            user_avatar: 'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png',
            full_name: this.props.auth.user.user_name || 'Support Playswap',
          },
          otherUser,
          {
            id: this.props.auth.user.uid,
            user_id: this.props.auth.user.uid,
            user_avatar: 'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png',
            full_name: this.props.auth.user.user_name || 'Support Playswap',
          },
          roomId,
          'support',
        );
      },
    );
  };

  sortChatRooms = () => {
    const rooms = Object.keys(this.props.rooms)
      .map((el) => {
        return { roomId: el.replace('rooms:', ''), ...this.props.rooms[el] };
      })
      .sort((a, b) => (b.timestamp || 0) * 1 - (a.timestamp || 0) * 1);
    const selected = this.state.activeRoom || '';
    if (selected && !this.props.rooms['rooms:' + selected]) {
      const room = {
        users: [
          {
            user_id: this.props.auth.user.uid,
          },
          this.state.targetUser,
        ],
        timestamp: new Date().getTime() / 1000,
        unseen: '',
        message: '',
      };
      this.setState({ roomList: [{ roomId: selected, ...room }, ...rooms.filter((el) => el.roomId !== selected)] });
    } else {
      this.setState({ roomList: rooms });
    }
  };

  searchUser = (keyword) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          field_name: 'status,keyword',
          field_value: `1,${keyword.toLowerCase()}`,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.props.getUserLists(this.state.filter);
      },
    );
  };

  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.searchUser, 500);
  handleEnter = (e) => {
    if (this.state.icon) this.uploadImage(this.state.icon);
  };
  handleInput = (e) => {
    e.preventDefault();
    e.target.style.height = 'auto';
    let height = e.target.scrollHeight < 300 ? e.target.scrollHeight : 300;
    e.target.style.height = height + 'px';
  };

  componentDidUpdate() {
    const that = this;
    const element = document.querySelector('#seller');
    window.choices = new window.Choices(element, {
      silent: true,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      placeholderValue: 'Search User',
      searchPlaceholderValue: 'Search User...',
      choices: [],
    });
    if (element && !window.choices) {
      window.choices.clearChoices();
      element.addEventListener(
        'search',
        function (event) {
          that.onInput(event.detail.value);
        },
        false,
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rooms !== this.props.rooms) {
      this.setState(
        {
          ...this.state,
          roomList: Object.keys(nextProps.rooms).map((el) => {
            return { roomId: el.replace('rooms:', ''), ...nextProps.rooms[el] };
          }),
        },
        () => {
          this.sortChatRooms();
        },
      );
    }
    if (nextProps.users !== this.props.users) {
      this.setState({
        ...this.state,
        userList: nextProps.users.users.users,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.users.users.users || []).map((el) => {
            return { value: el.id, label: el.name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }
  componentDidMount() {
    this.props.getRoomLists({
      type: 'support',
    });
    this.setState({
      height: window?.innerHeight - document.getElementById('chat-thread').offsetTop,
    });
  }

  render() {
    return (
      <div class="chat d-flex phoenix-offcanvas-container pt-1 mt-n1 mb-9">
        <div
          class="card p-3 p-xl-1 mt-xl-n1 chat-sidebar me-3 phoenix-offcanvas phoenix-offcanvas-start"
          id="chat-sidebar"
        >
          <div class="form-icon-container mb-4 d-sm-none d-xl-block">
            <select
              id="seller"
              class="form-select mb-3"
              onChange={(e) => this.getOrCreateChatSupport(e.target.value)}
            ></select>
          </div>
          <div class="scrollbar">
            <div class="tab-content" id="contactListTabContent">
              <div data-chat-thread-tab-content="data-chat-thread-tab-content">
                <ul class="nav chat-thread-tab flex-column list">
                  {(this.state.roomList || []).map((el) => {
                    const room = el;
                    const otherUser =
                      room['users'][0]['user_id'] == this.props.auth.user.uid ? room['users'][1] : room['users'][0];
                    const timeDisplay = room.timestamp
                      ? moment().diff(moment(room.timestamp * 1000), 'days') >= 1
                        ? moment(room.timestamp * 1000).format('l')
                        : moment(room.timestamp * 1000).format('LT')
                      : '';
                    let lastMsg = {
                      text: room?.message,
                    };
                    if (isJson(room?.message)) {
                      lastMsg = JSON.parse(room?.message);
                      if (lastMsg.image) {
                        lastMsg = {
                          text: 'Photo',
                        };
                      }
                    }
                    const unread = room.unseen == this.props.auth.user.uid;
                    return (
                      <li class={`nav-item ${unread ? 'unread' : 'read'}`} role="presentation">
                        <a
                          class={`nav-link d-flex align-items-center justify-content-center p-2 ${
                            this.state.activeRoom == room.roomId ? 'active' : ''
                          }`}
                          data-bs-toggle="tab"
                          data-chat-thread="data-chat-thread"
                          href="#tab-thread-1"
                          role="tab"
                          aria-selected="true"
                          onClick={() =>
                            this.setState(
                              {
                                targetUser: { ...otherUser },
                              },
                              () => {
                                this.setActiveRoom(room.roomId, {
                                  id: otherUser.user_id,
                                  user_id: otherUser.user_id,
                                  ...otherUser,
                                });
                              },
                            )
                          }
                        >
                          <div
                            class={`avatar avatar-xl ${
                              otherUser.is_active ? 'status-online' : 'status-offline'
                            } position-relative me-2 me-sm-0 me-xl-2`}
                          >
                            <img
                              class="rounded-circle border border-2 border-light-subtle"
                              src={
                                otherUser.avatar ||
                                'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                              }
                              alt=""
                            />
                          </div>
                          <div class="flex-1 d-sm-none d-xl-block">
                            <div class="d-flex justify-content-between align-items-center">
                              <h5 class="text-body fw-normal name text-nowrap">
                                {otherUser.full_name || otherUser.name}
                              </h5>
                              <p class="fs-10 text-body-tertiary text-opacity-85 mb-0 text-nowrap">{timeDisplay}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                              <p class="fs-9 mb-0 line-clamp-1 text-body-tertiary text-opacity-85 message">
                                {lastMsg.text ? lastMsg.text : ''}
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-content tab-content flex-1">
          <div class="tab-pane h-100 fade active show" id="tab-thread-1" role="tabpanel" aria-labelledby="tab-thread-1">
            <div class="card flex-1 h-100 phoenix-offcanvas-container">
              <div class="card-header p-3 p-md-4 d-flex flex-between-center">
                <div class="d-flex align-items-center">
                  <button
                    class="btn ps-0 pe-2 text-body-tertiary d-sm-none"
                    data-phoenix-toggle="offcanvas"
                    data-phoenix-target="#chat-sidebar"
                  >
                    <span class="fa-solid fa-chevron-left"></span>
                  </button>
                  <div class="d-flex flex-column flex-md-row align-items-md-center">
                    <button class="btn fs-7 fw-semibold text-body-emphasis d-flex align-items-center p-0 me-3 text-start">
                      <span class="line-clamp-1">{this.state.targetUser.full_name || this.state.targetUser.name}</span>
                    </button>
                    {this.state.targetUser.is_active && (
                      <p class="fs-9 mb-0 me-2">
                        {' '}
                        <span class="fa-solid fa-circle text-success fs-11 me-2"></span>Active now
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div class="chat-thread card-body p-3 p-sm-4 scrollbar" style={{ height: this.state.height + 'px' }}>
                <div id="chat-thread"></div>
              </div>
              {this.props.auth.user.uid == '55489c06-557a-40f0-ad0e-e910bf8b017c' && (
                <div class="card-footer">
                  {this.state.icon && (
                    <div
                      class="mb-2 position-relative"
                      onClick={() => {
                        this.setImageState(null);
                      }}
                    >
                      <img src={URL.createObjectURL(this.state.icon)} style={{ height: '50px' }} />
                      <span class="position-absolute top-10 start-60 translate-middle badge border border-light rounded-circle bg-danger p-1 fs-9">
                        <span>x</span>
                      </span>
                    </div>
                  )}
                  <textarea
                    className="outline-none scrollbar mb-2 form-control"
                    id="chat-message-input"
                    autoComplete="off"
                    autoFocus
                    placeholder="Type a message..."
                    onKeyUp={(e) => {
                      this.handleInput(e);
                      this.handleEnter(e);
                    }}
                  ></textarea>
                  <div class="d-flex justify-content-between align-items-end">
                    <div>
                      <label class="btn btn-link py-0 px-2 text-body fs-9" htmlFor="chatPhotos-0">
                        <span class="fa-solid fa-image"></span>
                      </label>
                      <input
                        class="d-none"
                        type="file"
                        accept="image/*"
                        id="chatPhotos-0"
                        onChange={(e) => this.handleImageUpload(e.target.files[0])}
                      />
                    </div>
                    <div>
                      <button
                        id="chat-message-send"
                        class="btn btn-primary fs-10"
                        type="button"
                        onClick={() => {
                          if (this.state.icon) this.uploadImage(this.state.icon);
                        }}
                      >
                        Send<span class="fa-solid fa-paper-plane ms-1"></span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatScreen.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  rooms: state.chat.rooms,
  users: state.users,
});

export default connect(mapStatetoProps, { getRoomLists, getUserLists })(ChatScreen);
