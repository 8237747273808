import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { getExpenseCategoryLists, deleteExpenseCategory } from '../../../actions/expense';

class ExpenseCategorys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: 10,
      },
      expenseList: [],
      total_items: 0,
      expense: {},
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.paginationHandler = this.paginationHandler.bind(this);
  }
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deleteExpenseCategory(id);
      const expenseList = this.state.expenseList.filter((el) => el._id !== id);
      this.setState({ expenseList });
    }
    return false;
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getExpenseCategoryLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expenses !== this.props.expenses) {
      this.setState({
        ...this.state,
        expenseList: nextProps.expenses.data,
      });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Accounting</a>
            </li>
            <li className="breadcrumb-item active">Expense Categories</li>
          </ol>
        </nav>
        {/* <h4 className="text-bold text-body-emphasis mb-3 text-uppercase">Expense Categories</h4> */}
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto"></div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
                <a href="expense-categories/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add expense category
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="name"
                      style={{ width: '80%', minWidth: '100px' }}
                    >
                      NAME
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.expenseList || []).length > 0 ? (
                    (this.state.expenseList || []).map((expense) => (
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="fs-9 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-8">
                            <input className="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                          </div>
                        </td>
                        <td className="seller align-middle white-space-nowrap">
                          <span className="text-body-emphasis">{expense?.name}</span>
                        </td>
                        <td className="joined align-middle white-space-nowrap text-body-tertiary text-end">
                          <div className="btn-reveal-trigger position-static">
                            <button
                              className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <span className="fas fa-ellipsis-h fs-10"></span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end py-2">
                              <a className="dropdown-item" href={`expense-categories/edit/${expense._id}`}>
                                <i class="fa-solid fa-pen-to-square me-1"></i>Edit
                              </a>
                              <a
                                className="dropdown-item text-danger"
                                href="#!"
                                onClick={() => this.handleDelete(expense._id)}
                              >
                                <i class="fa-solid fa-trash me-1"></i>Remove
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

ExpenseCategorys.propTypes = {
  auth: PropTypes.object.isRequired,
  expenses: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getExpenseCategoryLists: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  expenses: state.expenses.expense_categories,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  getExpenseCategoryLists,
  deleteExpenseCategory,
})(ExpenseCategorys);
