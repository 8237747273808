import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formatDateTime, convertCurrency } from '../../../utils/helpers';

import { getTransaction, updateTransaction } from '../../../actions/transaction';
import { withRouter } from '../../../withRouter';
import { TRANSACTION_STATUS_PAYED } from '../../../utils/constants';

const statuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Paid',
  'Completed',
  'Refund',
  'Fail',
  'Delivered',
  'Suspended',
  'On Hold',
  'Partial Refund',
];

const statusesColor = [
  'default',
  'warning',
  'warning',
  'success',
  'success',
  'warning',
  'danger',
  'success',
  'danger',
  'success',
  'success',
];

class FormTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: `View Transaction: ${this.props.router.params.id}`,
      refund: {},
      order: {},
    };
    this.updateStatus = this.updateStatus.bind(this);
  }

  updateStatus = async (record, status) => {
    await this.props.updateTransaction(record?.tx_id, {
      status: TRANSACTION_STATUS_PAYED,
    });
  };

  updateState(name, value) {
    this.setState({
      refund: {
        ...this.state.refund,
        [name]: value,
      },
    });
  }

  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getTransaction(this.props.router.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.transactions.transaction !== this.props.transactions.transaction) {
      this.setState({
        refund: nextProps.transactions.transaction.data,
        order:
          nextProps.transactions.transaction.data?.order?.length > 0
            ? nextProps.transactions.transaction.data?.order[0]
            : {},
      });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <div className="row p-3 card flex-1 h-100 phoenix-offcanvas-container">
          <div className="text-body text-hover-1000">
            {Object.keys(this.state.refund).length > 0 && (
              <div className="mb-0">
                <strong className="">ORDER ID</strong>: <span className="_id">{this.state.refund._id}</span>
                <br />
                <strong>Order Date</strong>: {formatDateTime(this.state.refund.created_time * 1000)}
                <br />
                <strong className="">Transaction ID</strong>:{' '}
                <span className="merchant_trade_no">{this.state.refund?.merchant_trade_no}</span>
                <br />
                <strong>Order Title</strong>: <span className="item_name">{this.state.refund?.item_name}</span>
                <br />
                <strong>Seller Name</strong>:{' '}
                <span>
                  <span className="">{this.state.refund?.seller_info?.user_name}</span>
                  <small> ({this.state.refund?.seller_info?.user_rank})</small>
                </span>
                <br />
                <strong>Buyer Name</strong>:{' '}
                <span className="item_name">{this.state.refund?.buyer_info?.user_name}</span>
                <br />
                <strong>Order Price</strong>:{' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: convertCurrency(this.state.refund?.price, true, this.props.settings?.data?.sgd),
                  }}
                ></span>
                <br />
                <strong>Order Status</strong>:{' '}
                <span
                  className={`badge badge-phoenix fs-10 badge-phoenix-${
                    statusesColor[this.state.refund.status]
                  } d-inline-flex align-items-center`}
                >
                  {statuses[this.state.refund?.status]}
                </span>
                <br />
                <br />
                {this.state.refund.status < TRANSACTION_STATUS_PAYED && (
                  <button
                    class="btn btn-primary mt-4"
                    type="submit"
                    onClick={() => this.updateStatus(this.state.refund)}
                  >
                    MARK AS PAID
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

FormTransaction.propTypes = {
  auth: PropTypes.object.isRequired,
  transactions: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStateToProps, {
  getTransaction,
  updateTransaction,
})(withRouter(FormTransaction));
