import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { withRouter } from '../../../withRouter';
import { getTransactionsDetail } from '../../../actions/transaction';
import { handleChat, cleanup } from '../../../utils/chatHelper';
import { getUserId, formatDateTime } from '../../../utils/helpers';

class ChatScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrder: {},
      height: '',
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.router.params.id) {
        this.props.getTransactionsDetail(this.props.router.params.id);
        this.setState({
          height: window?.innerHeight - document.getElementById('chat-thread').offsetTop - 150,
        });
      }
    }
  }

  componentWillUnmount() {
    cleanup();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.transactions !== nextProps.transactions) {
      this.setState({
        currentOrder: nextProps.transactions?.transaction?.data,
      });
      handleChat(
        {
          id: nextProps.transactions?.transaction?.data?.buyer_id,
          ...nextProps.transactions?.transaction?.data?.buyer_info,
        },
        {
          id: nextProps.transactions?.transaction?.data?.seller_id,
          ...nextProps.transactions?.transaction?.data?.seller_info,
        },
        {
          id: nextProps.transactions?.transaction?.data?.seller_id,
          ...nextProps.transactions?.transaction?.data?.seller_info,
        },
        nextProps.transactions?.transaction?.data?._id,
      );
    }
  }

  render() {
    const { user } = this.props.auth.user;
    return (
      <div>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-1 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <div class="chat-content tab-content flex-1">
          <div class="tab-pane h-100 fade active show" id="tab-thread-1" role="tabpanel" aria-labelledby="tab-thread-1">
            <div class="card flex-1 h-100 phoenix-offcanvas-container">
              <div class="card-header p-2 p-md-4 d-flex flex-between-center">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center fs-9">
                      <span> <strong>Game:</strong> {this.state.currentOrder?.category_name}</span>
                    </div>
                    <div class="d-flex align-items-center fs-9">
                      <span class="line-clamp-1"><strong>Product Name:</strong> {this.state.currentOrder?.item_name}</span>
                    </div>
                    <div class="fs-9 d-flex align-items-center">
                      <span class="me-2"><strong>Order ID:</strong> {this.state.currentOrder?._id}</span>
                    </div>
                    <div class="fs-9 d-flex align-items-center">
                      <span class="me-2"><strong>Order Purchase Date:</strong> {formatDateTime(this.state.currentOrder?.created_time * 1000)}</span>
                    </div>

                    <div class="fs-9 d-flex align-items-center">
                      <span class="me-2">
                        <strong>Seller:</strong> {this.state.currentOrder?.seller_info?.user_name}{' '}({getUserId(this.state.currentOrder?.seller_id)})
                      </span>
                      {this.state.currentOrder?.seller_info?.is_online ? (
                        <span class="fs-9 mb-0 me-2 d-flex  align-items-center">
                          <span class="text-success fs-9 me-2"><strong>Active now</strong></span>
                        </span>
                      ) : (
                        <div class="fs-9 mb-0 me-2 fst-italic opacity-75">
                          Last seen {moment(this.state.currentOrder?.seller_info?.last_seen * 1000).fromNow()}
                        </div>
                      )}
                    </div>
                    <div class="fs-9 d-flex align-items-center">
                      <span class="me-2">
                        <strong>Buyer:</strong> {this.state.currentOrder?.buyer_info?.user_name}{' '}({getUserId(this.state.currentOrder?.buyer_id)})
                      </span>
                      {this.state.currentOrder?.buyer_info?.is_online ? (
                        <span class="fs-9 mb-0 me-2 d-flex align-items-center">
                          <span class="text-success fs-9 me-2"><strong>Active now</strong></span>
                        </span>
                      ) : (
                        <div class="fs-9 mb-0 me-2 fst-italic opacity-75">
                          Last seen {moment(this.state.currentOrder?.buyer_info?.last_seen * 1000).fromNow()}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-thread card-body p-3 p-sm-4 scrollbar" style={{ height: this.state.height + 'px' }}>
                <div id="chat-thread"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChatScreen.propTypes = {
  auth: PropTypes.object.isRequired,
  getTransactionsDetail: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions,
});

export default connect(mapStatetoProps, { getTransactionsDetail })(withRouter(ChatScreen));
