import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getGeneralReports } from '../../../actions/report';
import { convertCurrency } from '../../../utils/helpers';

class GeneralReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {},
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    };
    this.calculateCurrentBalance = this.calculateCurrentBalance.bind(this);
  }
  componentDidMount() {
    this.props.getGeneralReports({ month: new Date().getMonth() + 1, year: new Date().getFullYear() });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.report !== nextProps.report) {
      this.setState({
        report: nextProps.report?.data[0] || {},
      });
    }
    if (this.props.month !== nextProps.month || this.props.year !== nextProps.year) {
      this.setState({
        month: nextProps.month,
        year: nextProps.year,
      });
      this.props.getGeneralReports({ month: nextProps.month, year: nextProps.year });
    }
  }

  calculateCurrentBalance() {
    const report = this.state.report;
    const revenue = report.total_revenue || 0;
    const payout = report.total_payout || 0;
    const chargeback = report.total_charge_back || 0;
    const expense = report.total_expense;
    return revenue - payout - chargeback;
  }

  render() {
    return (
      <div className="px-3 mb-6">
        <div className="row justify-content-between">
          <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pb-xxl-0 ">
            <span className="uil fs-5 lh-1 uil-money-stack text-primary"></span>
            <h1 className="fs-5 pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: convertCurrency(this.state.report.total_revenue || 0, false, this.props.settings?.data?.sgd),
                }}
              />
            </h1>
            <p className="fs-9 mb-0">Revenue</p>
          </div>
          <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-xxl-0 border-bottom-xxl-0 border-end-md border-bottom pb-4 pb-xxl-0">
            <span className="uil fs-5 lh-1 uil-money-withdraw text-info"></span>
            <h1 className="fs-5 pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: convertCurrency(this.state.report.total_profit || 0, false, this.props.settings?.data?.sgd),
                }}
              />
            </h1>
            <p className="fs-9 mb-0">Profit</p>
          </div>
          <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-bottom-xxl-0 border-bottom border-end border-end-md-0 pb-4 pb-xxl-0 pt-4 pt-md-0">
            <span className="uil fs-5 lh-1 uil-money-insert text-primary"></span>
            <h1 className="fs-5 pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: convertCurrency(this.state.report.total_payout || 0, false, this.props.settings?.data?.sgd),
                }}
              />
            </h1>
            <p className="fs-9 mb-0">Payout</p>
          </div>
          <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-md border-end-xxl-0 border-bottom border-bottom-md-0 pb-4 pb-xxl-0 pt-4 pt-xxl-0">
            <span className="uil fs-5 lh-1 uil-money-withdrawal text-info"></span>
            <h1 className="fs-5 pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: convertCurrency(this.state.report.total_refund || 0, false, this.props.settings?.data?.sgd),
                }}
              />
            </h1>
            <p className="fs-9 mb-0">Refund</p>
          </div>
          <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end border-end-xxl-0 pb-md-4 pb-xxl-0 pt-4 pt-xxl-0">
            <span className="uil fs-5 lh-1 uil-moneybag-alt text-success"></span>
            <h1 className="fs-5 pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: convertCurrency(
                    this.state.report.total_charge_back || 0,
                    false,
                    this.props.settings?.data?.sgd,
                  ),
                }}
              />
            </h1>
            <p className="fs-9 mb-0">Chargeback</p>
          </div>
          <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-xxl pb-md-4 pb-xxl-0 pt-4 pt-xxl-0">
            <span className="uil fs-5 lh-1 uil-balance-scale text-danger"></span>
            <h1 className="fs-5 pt-3">
              <span
                dangerouslySetInnerHTML={{
                  __html: convertCurrency(this.calculateCurrentBalance(), false, this.props.settings?.data?.sgd),
                }}
              />
            </h1>
            <p className="fs-9 mb-0">Current Balance</p>
          </div>
        </div>
      </div>
    );
  }
}

GeneralReport.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  report: state.report.general,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, { getGeneralReports })(GeneralReport);
