import _ from 'lodash';

import { GET_RANK_LIST, UPDATE_RANK, DELETE_RANK, CREATE_RANK, GET_RANK_DETAIL } from '../actions/types';

const initialState = {
  royaltys: {},
  royalty: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_RANK_LIST:
      return {
        ...state,
        royaltys: action.payload,
      };
    case GET_RANK_DETAIL:
      return {
        ...state,
        royalty: action.payload,
      };
    case CREATE_RANK:
    case UPDATE_RANK:
    case DELETE_RANK:
      return {
        ...state,
      };
    default:
      return state;
  }
}
