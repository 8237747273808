import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from '../../../withRouter';
import PropTypes from 'prop-types';

const SUSPEND_DAY = {
  7: '7 Days',
  14: '14 Days',
  0: 'Permanent',
};

class Suspend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suspend_day: 0,
      loading: false,
    };
  }
  handleOk = () => {
    this.props.handleOk(this.state.suspend_day);
  };

  render() {
    return (
      <div class="modal fade" id="disputeModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Suspended User
                </h5>
                <button class="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                  <span class="fas fa-times fs-9"></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-floating mb-3">
                  <select
                    type="number"
                    class="form-control"
                    id="suspendDay"
                    required
                    value={this.state.suspend_day}
                    onChange={(e) => this.setState({ suspend_day: e.target.value })}
                  >

                    {/* <option value="">Select number of days to suspend user</option> */}

                    {Object.keys(SUSPEND_DAY).map((el) => (
                      <option value={el}>{SUSPEND_DAY[el]}</option>
                    ))}
                  </select>
                  <label for="suspendDay">Number of days to suspend user</label>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" disabled={this.state.loading} onClick={this.handleOk}>
                  Save
                </button>
                <button
                  class="btn btn-outline-primary"
                  type="button"
                  data-bs-dismiss="modal"
                  id="modalClose"
                  onClick={() =>
                    this.setState({
                      suspend_day: 0,
                      loading: true,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Suspend.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(Suspend));
