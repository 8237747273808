import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { formValidation } from '../../../validations/authValidate';
import { createNotification, pasteIntoInput } from '../../../utils/helpers';
import lang from '../../../langs';
import { createMarketingBanner, updateMarketingBanner, getMarketingBanner } from '../../../actions/marketing';
import { withRouter } from '../../../withRouter';

class FormMarketingBanners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.router.params.id ? 'Edit Marketing Banner' : 'Create Marketing Banner',
      banner: {},
      target_urls: [],
      tags: [],
    };
    this.handleSave = this.handleSave.bind(this);
  }

  updateState(name, value) {
    let params = { [name]: value };
    this.setState({
      banner: {
        ...this.state.banner,
        ...params,
      },
    });
  }

  processData = (values) => {
    if (this.props.router.params.id) {
      this.props.updateMarketingBanner(this.props.router.params.id, values);
    } else {
      this.props.createMarketingBanner(values);
    }

    createNotification('success', lang('success.update'));
    this.setState({ loading: false });
  };

  async handleSave(e) {
    e.preventDefault();
    if (this.props.auth.isAuthenticated) {
      this.setState({ loading: true });
      const values = { ...this.state.banner };
      values.embeded_code = window.tinymce.get('description').getContent();
      values.target_urls = this.state.target_urls;
      values.tags = this.state.tags;
      if (!values.embeded_code || (values.target_urls.length == 0 && values.tags.length == 0)) {
        createNotification('error', 'Please enter Embeded Code and Target urls or Tags');
      } else {
        this.processData(values);
      }
    }
  }
  addChoice(key) {
    const value = this.state[key];
    value.push('');
    this.setState({
      [key]: value,
    });
  }
  handleInput = (e) => {
    e.preventDefault();
    e.target.style.height = 'auto';
    let height = e.target.scrollHeight < 300 ? e.target.scrollHeight : 300;
    e.target.style.height = height + 'px';
  };
  handleKeyUp = (e, key) => {
    if (e.keyCode == 13 && (e.shiftKey || e.altKey)) {
      if (e.type == 'keypress') {
        pasteIntoInput(this, '\n');
      }
    } else if (e.keyCode === 13) {
      const message = e.target.value;
      if ((message || '') === (this.state[key] || []).join('\n')) {
        this.addChoice(key);
      } else {
        this.handleTextareaValue(message, key);
      }
    }
  };

  handleTextareaValue = (message, key = 'target_urls') => {
    const validateMessage = (message || '').trim().split('\n');
    let value = this.state[key];
    if ((message || '') !== (value || []).join('\n')) {
      value = validateMessage;
      this.setState({
        [key]: value,
      });
    }
  };
  componentDidUpdate() {
    console.log(this.state.banner?.embeded_code);
    setTimeout(() => {
      if (this.state.banner?.embeded_code)
        window.tinymce.get('description').setContent(this.state.banner?.embeded_code);
    }, 200);
  }
  componentDidMount() {
    if (this.props.router.params.id && this.props.auth.isAuthenticated) {
      this.props.getMarketingBanner(this.props.router.params.id);
    }

    window.tinymce
      .createEditor('description', {
        menubar: true,
        statusbar: false,
        plugins: 'link,image,lists,table,media',
        theme_advanced_toolbar_align: 'center',
        relative_urls: false,
        convert_urls: false,
        toolbar: [
          { name: 'history', items: ['undo', 'redo'] },
          {
            name: 'formatting',
            items: ['bold', 'italic', 'underline', 'strikethrough', 'forecolor'],
          },
          {
            name: 'heading',
            items: ['h1', 'h2', 'h3'],
          },
          {
            name: 'alignment',
            items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
          },
          { name: 'list', items: ['numlist', 'bullist'] },
          { name: 'link', items: ['link'] },
        ],
      })
      .render();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.banner !== this.props.banner) {
      this.setState({
        banner: nextProps.banner.data,
        target_urls: nextProps.banner.data.target_urls,
        tags: nextProps.banner.data.tags,
      });
    }
  }
  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">{this.state.title}</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">{this.state.title}</h2>
        <div className="row align-items-center justify-content-between g-3 mb-4">
          <div className="col col-auto">
            <Link to={-1} className="btn btn-link text-body me-4 px-0">
              <span className="fa-solid fa-arrow-left fs-9 me-2"></span> Back
            </Link>
          </div>
        </div>
        <form className="row g-3 needs-validation" onSubmit={this.handleSave} noValidate="">
          <div className="col-12">
            <div className="mb-6">
              <h4 className="mb-3">Name</h4>
              <input
                className="form-control"
                id="inputFullName"
                type="text"
                required="required"
                value={this.state.banner?.name}
                onChange={(e) => {
                  this.updateState('name', e.target.value);
                }}
              />
              <div className="invalid-feedback">Please enter name</div>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Target URLs</h4>
              <div className="mb-2">
                <textarea
                  className="form-control"
                  placeholder="Please break line to add additional value"
                  style={{ height: '50px', width: '100%' }}
                  onKeyUp={(e) => {
                    this.handleKeyUp(e, 'target_urls');
                    this.handleInput(e);
                  }}
                  onChange={(e) => {
                    this.handleTextareaValue(e.target.value, 'target_urls');
                  }}
                  value={(this.state.target_urls || []).join('\n')}
                ></textarea>
              </div>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Tags</h4>
              <div className="mb-2">
                <textarea
                  className="form-control"
                  placeholder="Please break line to add additional value"
                  style={{ height: '50px', width: '100%' }}
                  onKeyUp={(e) => {
                    this.handleKeyUp(e, 'tags');
                    this.handleInput(e);
                  }}
                  onChange={(e) => {
                    this.handleTextareaValue(e.target.value, 'tags');
                  }}
                  value={(this.state.tags || []).join('\n')}
                ></textarea>
              </div>
            </div>
            <div className="mb-6">
              <h4 className="mb-3">Embeded Code</h4>
              <textarea class="tinymce" name="content" id="description"></textarea>
            </div>
            <div className="col-12">
              {!this.state.loading ? (
                <button className="btn btn-primary" type="submit">
                  Save
                </button>
              ) : (
                <button className="btn btn-primary" type="button" disabled="">
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
              )}
            </div>
          </div>
        </form>
      </>
    );
  }
}

FormMarketingBanners.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  createMarketingBanner: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
  banner: state.marketings.banner,
});

export default connect(mapStateToProps, {
  createMarketingBanner,
  updateMarketingBanner,
  getMarketingBanner,
})(withRouter(FormMarketingBanners));
