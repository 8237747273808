import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getPaymentMethodLists, deletePaymentMethod } from '../../../actions/payment';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { convertCurrency } from '../../../utils/helpers';

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      paymentMethodList: [],
      total_items: 0,
    };
  }
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getPaymentMethodLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.deletePaymentMethod(id);
      this.paginationHandler({ selected: 0 });
    }
    return false;
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.paymentMethods !== this.props.paymentMethods) {
      this.setState({
        ...this.state,
        paymentMethodList: nextProps.paymentMethods.paymentMethods.data,
      });
    }
  }
  render() {
    return (
      <>
        <nav class="mb-2" aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li class="breadcrumb-item active">Payment methods</li>
          </ol>
        </nav>
        {/* <h4 class="text-bold text-body-emphasis mb-3 text-uppercase">Payment methods</h4> */}
        <div
          id="members"
          data-list='{"valueNames":["username","email","role","security","last_active","joined"],"page":10,"pagination":true}'
        >
          <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col col-auto">
              <div class="search-box">
                <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    class="form-control search-input search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center">
                <button class="btn btn-link text-body me-4 px-0">
                  <span class="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
                <a href="payment-methods/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div class="table-responsive scrollbar ms-n1 ps-1">
              <table class="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th class="white-space-nowrap fs-9 align-middle ps-0">
                      <div class="form-check mb-0 fs-8">
                        <input
                          class="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="priority"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      PRIORITY
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '20%', minWidth: '200px' }}
                    >
                      NAME
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      PROVIDER
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      METHOD
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="area"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      AREA
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      SELLER'S FEES
                    </th>
                    <th
                      class="sort align-middle text-center"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      BUYER'S FEES
                    </th>
                    <th class="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody class="list" id="members-table-body">
                  {this.state.paymentMethodList.map((method) => (
                    <tr class="hover-actions-trigger btn-reveal-trigger position-static">
                      <td class="fs-9 align-middle ps-0 py-3">
                        <div class="form-check mb-0 fs-8">
                          <input class="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                        </div>
                      </td>
                      <td class="priority align-middle white-space-nowrap text-center">{method.priority}</td>
                      <td class="customer align-middle white-space-nowrap text-center">
                        <div class="d-flex align-items-center text-body">
                          <div class="avatar avatar-m">
                            <img
                              class="rounded-circle"
                              src={
                                method.image_url ||
                                'https://dewcare.co.za/wp-content/uploads/2020/10/blank-avatar-300x300.png'
                              }
                              alt=""
                            />

                          </div>
                          <h6 class="username mb-0 ms-1 fw-semibold ">{method.name}</h6>
                        </div>
                      </td>
                      <td class="customer align-middle white-space-nowrap text-center">
                        <h6 class="username mb-0 fw-semibold">{method.provider_platform}</h6>
                      </td>
                      <td class="customer align-middle white-space-nowrap text-center">
                        <h6 class="username mb-0 fw-semibold">{method.pay_platform}</h6>
                      </td>
                      <td class="area align-middle white-space-nowrap text-center">
                        <h6 class="area mb-0 fw-semibold">{method.allowed_area}</h6>
                      </td>
                      <td class="mobile_number align-middle white-space-nowrap text-center">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(method.fee_charge_seller, true, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td class="mobile_number align-middle white-space-nowrap text-center">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(method.fee_charge_buyer, true, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td class="joined align-middle white-space-nowrap text-body-tertiary text-center">
                        <div class="btn-reveal-trigger position-static">
                          <button
                            class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-boundary="window"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-bs-reference="parent"
                          >
                            <span class="fas fa-ellipsis-h fs-10"></span>
                          </button>
                          <div class="dropdown-menu dropdown-menu-end py-2">
                            <a class="dropdown-item" href={`payment-methods/edit/${method._id}`}>
                              <i class="fa-solid fa-pen-to-square ms-1"></i> Edit
                            </a>
                            <a
                              class="dropdown-item text-danger"
                              onClick={() => this.handleDelete(method._id)}
                              href="#!"
                            >
                              <i class="fa-solid fa-trash ms-1"></i> Remove
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

PaymentMethods.propTypes = {
  auth: PropTypes.object.isRequired,
  paymentMethods: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getPaymentMethodLists: PropTypes.func.isRequired,
  deletePaymentMethod: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  paymentMethods: state.paymentMethods,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getPaymentMethodLists,
  deletePaymentMethod,
})(PaymentMethods);
