import _ from 'lodash';
import lang from '../langs';
import rest from '../utils/rest';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { NotificationManager } from 'react-notifications';
import DOMPurify from 'isomorphic-dompurify';

export const escapeHTML = (content) => {
  return DOMPurify.sanitize(content, { FORBID_TAGS: ['style', 'script', 'iframe', 'a'] });
};

export const setAuthToken = (token) => {
  if (token) {
    // Apply to every request
    rest.defaults.headers.common['access-token'] = token;
  } else {
    // Delete auth header
    delete rest.defaults.headers.common['access-token'];
  }
};

export const createNotification = (type, content) => {
  switch (type) {
    case 'info':
      NotificationManager.info(content, 'Info');
      break;
    case 'success':
      NotificationManager.success(content, 'Great!');
      break;
    case 'warning':
      NotificationManager.warning(content, 'Warning message');
      break;
    case 'error':
      NotificationManager.error(content, 'Error message');
      break;
  }
};

export const beforeRequest = () => (dispatch) => {
  // loading
  dispatch(showLoading('sectionBar'));
};

export const beforeResponse = () => (dispatch) => {
  // loading
  dispatch(hideLoading('sectionBar'));
};

export const urlExists = (url) => {
  var http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status != 404;
};

export const validateRequired = (data, filterData) => {
  let output = {};
  filterData.map((key) => {
    if (_.has(data, key) && (data[key] === '' || data[key] === null || data[key] === undefined)) {
      output[key] = lang('required.' + key);
    }
  });

  return output;
};

export const findStatusText = (data) => (status) => {
  return _.findKey(data, status) ? true : false;
};

export const encodeUri = (uri) => {
  const str = uri.replace(' ', '%20');
  return encodeURIComponent(str);
};

export const convertCurrency = (price, convert = true, rate = 1.35) => {
  const currency = rate ? rate : 1.35;
  const sgd = ((price || 0) * currency).toLocaleString('en-US', {
    style: 'currency',
    currency: 'SGD',
  });
  const usd = ((price || 0) * 1).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  if (convert) return `<span class="fw-semibold text-danger">${usd}</span> <br>(${sgd})</br>`;
  else return `<span>${usd}</span>`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return date.toLocaleDateString('en-US', options);
};

export const imgModal = (src) => {
  const modal = document.createElement('div');
  modal.setAttribute('class', 'modal_img');
  document.querySelector('body').append(modal);
  const newImage = document.createElement('img');
  newImage.setAttribute('src', src);
  const closeBtn = document.createElement('i');
  closeBtn.setAttribute('class', 'closeBtn');
  closeBtn.onclick = () => {
    modal.remove();
  };
  modal.append(newImage, closeBtn);
};

export const pasteIntoInput = (el, text) => {
  el.focus();
  if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
    var val = el.value;
    var selStart = el.selectionStart;
    el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd);
    el.selectionEnd = el.selectionStart = selStart + text.length;
  } else if (typeof document.selection != 'undefined') {
    var textRange = document.selection.createRange();
    textRange.text = text;
    textRange.collapse(false);
    textRange.select();
  }
};

export const getUserId = (userID) => {
  const id = userID?.split('-')[0] || '';
  return id.toUpperCase();
};
