import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMembershipLists, membershipDataDelete } from '../../../actions/membership';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';

class SellerRanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      membershipList: [],
      total_items: 0,
    };
  }

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getMembershipLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  handleDelete = (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      this.props.membershipDataDelete(id);
      const membershipList = this.state.membershipList.filter((el) => el._id !== id);
      this.setState({
        ...this.state,
        membershipList,
      });
    }
    return false;
  };
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.memberships !== this.props.memberships) {
      this.setState({
        ...this.state,
        membershipList: nextProps.memberships.memberships.data,
      });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active ">Seller ranks</li>
          </ol>
        </nav>
        {/* <h5 className="text-bold text-body-emphasis mb-3 text-uppercase text-center">Seller ranks</h5> */}
        <div id="members">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto">
              <div className="search-box">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    className="form-control search-input search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
                <a href="seller-ranks/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add Rank
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      RANK
                    </th>
                    {/* <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: "15%", minWidth: "200px" }}
                    >
                      BADGE
                    </th> */}
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      MILESTONE
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      ONHOLD DAYS
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      TRANSACTION FEES
                    </th>
                    <th
                      className="sort align-middle text-center"
                      scope="col"
                      data-sort="last_active"
                      style={{ width: '10%', minWidth: '200px' }}
                    >
                      ORDER AMOUNT
                    </th>
                    <th className="sort align-middle text-center" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.membershipList.map((membership) => (
                    <tr class="hover-actions-trigger btn-reveal-trigger position-static">
                      <td class="fs-9 align-middle ps-0 py-3">
                        <div class="form-check mb-0 fs-8">
                          <input class="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                        </div>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold text-center">{membership.rank}</h6>
                      </td>
                      {/* <td class="customer align-middle white-space-nowrap">
                        <a
                          class="d-flex align-items-center text-body text-hover-1000"
                          href="#!"
                        >
                          <div class="avatar avatar-m">
                            <img
                              class="rounded-circle"
                              src="../assets/img/team/32.webp"
                              alt=""
                            />
                          </div>
                        </a>
                      </td> */}
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold text-center">{membership.total_revenue}</h6>
                      </td>
                      <td class="mobile_number align-middle white-space-nowrap text-center">{membership.number_of_on_hold_days}</td>
                      <td class="mobile_number align-middle white-space-nowrap text-center text-danger fw-semibold">{membership.fee_charge_seller}%</td>
                      <td class="mobile_number align-middle white-space-nowrap text-center">{membership.order_amount}</td>
                      <td class="joined align-middle white-space-nowrap text-body-tertiary text-center">
                        <div class="btn-reveal-trigger position-static">
                          <button
                            class="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-boundary="window"
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-bs-reference="parent"
                          >
                            <span class="fas fa-ellipsis-h fs-10"></span>
                          </button>
                          <div class="dropdown-menu dropdown-menu-end py-2">
                            <a class="dropdown-item" href={`seller-ranks/edit/${membership._id}`}>
                              <i class="fa-solid fa-pen-to-square me-1"></i>Edit
                            </a>
                            <a class="dropdown-item text-danger" onClick={() => this.handleDelete(membership._id)}>
                              <i class="fa-solid fa-trash me-1"></i> Remove
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SellerRanks.propTypes = {
  auth: PropTypes.object.isRequired,
  memberships: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getMembershipLists: PropTypes.func.isRequired,
  membershipDataDelete: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  memberships: state.memberships,
  errors: state.errors,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStatetoProps, {
  getMembershipLists,
  membershipDataDelete,
})(SellerRanks);
